import React from 'react'
import Layout from '../components/layout/layout'
import TextContainer from '../components/elements/textContainer'
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import newYorkTimes from '../images/common/nytBestsellerSadhguruBlack.svg'
import EndorsementsSection from '../components/page/index/endorsementsSection'
import EventsSection from '../components/page/index/eventsSection/eventsSection'
import { ButtonWithSubtext } from '../components/elements/buttonWithSubtext'

const tanBg = '#F7F5F0'

const PageHeading = (props) => (
  <Heading
    variant={'h1'}
    color={'tan.heading'}
    fontSize={['32px', '48px']}
    {...props}
  />
)

const HeaderSection = () => (
  <Box position={'relative'}>
    <Box display={['block', 'none']}>
      <StaticImage
        src={'../images/whatIsKarma/headerBgMobile.jpg'}
        alt={'Background'}
        layout={'fullWidth'}
        placeholder={'blurred'}
        quality={80}
      />
    </Box>
    <Box display={['none', 'block']}>
      <StaticImage
        src={'../images/whatIsKarma/headerBgDesktop.jpg'}
        alt={'Background'}
        layout={'fullWidth'}
        placeholder={'blurred'}
        quality={80}
      />
    </Box>
    <Flex
      position={'absolute'}
      top={0}
      left={0}
      width={'100%'}
      height={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
      alignContent={'center'}
      flexWrap={'wrap'}
    >
      <Box
        width={['41vw', '17vw']}
        mr={[0, '4.5vw', '3vw']}
        ml={['6vw', 0]}
        style={{ filter: 'drop-shadow(1px 5px 7px #5a5b6bFe)' }}
        order={[1, -1]}
      >
        <StaticImage
          src={'../images/whatIsKarma/headerBook.jpg'}
          alt={"Karma: A Yogi's Guide to Crafting Your Destiny"}
          width={800}
          placeholder={'tracedSVG'}
        />

        <Flex justifyContent={'center'} width={'100%'} mt={['3vw', '1vw']}>
          <ButtonWithSubtext
            fontSize={['2.9vw', '2vw', '1.5vw', '1.2vw']}
            width={'auto'}
            height={'auto'}
            p={['2vw 2vw', '1vw 2vw']}
            mx={[0, '-6vw']}
            text={'Start Exploring Karma'}
            subText={'Buy the Book'}
            subTextProps={{ fontSize: '.9em' }}
            href={'/#order'}
          />
        </Flex>
      </Box>
      <Box width={['45vw', '55vw', '41vw']}>
        <Box display={['block', 'none']}>
          <StaticImage
            src={'../images/whatIsKarma/logosMobile.png'}
            alt={'Accolades'}
            width={500}
            placeholder={'tracedSVG'}
          />
        </Box>
        <Box display={['none', 'block']}>
          <StaticImage
            src={'../images/whatIsKarma/logosDesktop.png'}
            alt={'Accolades'}
            width={1200}
            placeholder={'tracedSVG'}
          />
        </Box>
      </Box>
    </Flex>
  </Box>
)

const TopText = () => (
  <Box bgColor={tanBg} pt={[12, 14]}>
    <Container>
      <TextContainer>
        <PageHeading>What is Karma</PageHeading>
        <p>
          Have you ever wondered or thought about the word karma? What does
          karma actually mean? Is karma real or a made up concept spread down
          through the ages? Is karma individual, collective or both? What are
          the types of karma? Is it simply what goes around comes around or does
          it mean something more profound than that?
        </p>
        <PageHeading>What Karma Means?</PageHeading>
        <p>
          Describing a term long used and misused in different ways, Sadhguru
          explains the meaning of karma. Explaining how very deep rooted its
          influences are, he also shows a way to get a "handle" over the complex
          structure that it is.
        </p>
        <p>
          Epigenetics, a branch of biology which studies{' '}
          <Link
            isExternal={true}
            href='http://discovermagazine.com/2013/may/13-grandmas-experiences-leave-epigenetic-mark-on-your-genes#.Ub8xcfYY3R4'
          >
            how a human being's behaviors and life experiences impact their DNA,
            and therefore can be passed on from generation to generation
          </Link>
          , is being explored by scientists today. However, in the yogic
          perspective, the influence of the past goes way beyond our ancestors,
          to the very first life form on the planet. Sadhguru{' '}
          <a href='https://isha.sadhguru.org/in/en/wisdom/article/what-is-karma'>
            explains the meaning of karma
          </a>
          , a term long used and misused in different contexts. Explaining how
          very deep rooted its influences are, he also shows a way to get a
          "handle" over the complex structure that it is.
        </p>
        <p>
          <strong>Sadhguru</strong>: &ldquo;
          <Link
            isExternal={true}
            href='http://isha.sadhguru.org/blog/yoga-meditation/is-my-karma-good-or-bad/'
          >
            Karma
          </Link>{' '}
          means action or the imprint of action which remains within us. The
          actions that your father performed are working and kicking up within
          you not just in your situation, but within every cell in your body. It
          is not so easy to get rid of your parents! You may have noticed that
          when you were eighteen or twenty, you completely rebelled against your
          father or your mother, but by the time you are forty or forty-five,
          you begin to speak like them, act like them, and even look like them.
          That is a hopeless way to live because if this generation is going to
          behave, act, live and experience life just the way the previous
          generation did, this is a wasted generation. This generation should
          experience life in a way the previous generation never even imagined.
          I don&rsquo;t mean you should do something crazy on the street, I mean
          the way you experience life can be completely altered. It can be taken
          to the next level of experience.
        </p>
        <p>
          The problem has come not because of karma, the problem has come
          because you got entangled in it, you got enmeshed in it. If a little
          distance comes between you and your body, between you and your mind,
          then whatever the karma, it has no impact on you. You can still make
          use of it to be embodied and live, you can still maintain a certain
          individual character but it does not become a binding, it becomes a
          stepping stone.&rdquo;
        </p>
      </TextContainer>
    </Container>
  </Box>
)

const BookSection = () => (
  <Box bgColor={tanBg} pb={16}>
    <Container maxWidth={'container.lg'} pt={12} pb={16}>
      <Grid
        gridTemplateRows={['400px auto', 'min-content auto']}
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridColumnGap={{ base: 0, md: 8 }}
      >
        <Box gridArea={{ md: '1/1/-1/2' }}>
          <StaticImage
            src={'../images/whatIsKarma/karmaBookPerspective.jpg'}
            alt={"Karma: A Yogi's Guide to Crafting Your Destiny"}
            placeholder={'tracedSVG'}
            objectFit={'contain'}
            objectPosition={'top center'}
            width={800}
          />
        </Box>
        <TextContainer>
          <Heading
            as={'h1'}
            textStyle={'heading'}
            fontSize={{ base: '48px', md: '72px' }}
            textTransform={'uppercase'}
            lineHeight={'1.2'}
            textAlign={'center'}
            mb={[6, 12]}
          >
            Karma
            <Box
              component={'span'}
              fontSize={'.3em'}
              display={'block'}
              fontFamily={'fedraSans'}
              fontWeight={'bold'}
            >
              A Yogi's Guide To
              <br />
              Crafting Your Destiny
            </Box>
          </Heading>

          <Image
            src={newYorkTimes}
            htmlWidth={'797.49px'}
            htmlHeight={'268.91px'}
            maxWidth={{ base: '180px', md: '256px' }}
            mx={'auto'}
            mb={[10, 10]}
          />

          <Box textAlign={'center'} mb={12}>
            <ButtonWithSubtext
              width={'auto'}
              text={'Start Exploring Karma'}
              subText={'Buy the Book'}
              href={'/#order'}
              fontSize={['md', 'sm', 'md']}
              subTextProps={{ fontSize: '0.9em' }}
            />
          </Box>
        </TextContainer>
        <Box gridColumnStart={{ base: 1, md: 2 }} gridColumnEnd={[2, 3, 2]}>
          <TextContainer>
            <p>
              For the first time, New York Times bestselling author and
              world-renowned yogi, mystic and visionary, Sadhguru, unravels the
              existential mechanism of karma. What is karma? The popular
              misinterpretation of karma as a reward and punishment system
              leaves most people in despair as if nothing is truly in their
              control. Karma is a profound tell-all book on the word for the
              modern era and the most empowering tool for human wellbeing.
            </p>
            <p>
              Sadhguru's ability to make complex ancient methods accessible to
              modern audiences catapulted his previous book Inner Engineering: A
              Yogi&rsquo;s Guide to Joy onto the New York Times bestseller
              list.&nbsp;
            </p>
          </TextContainer>
        </Box>
      </Grid>
    </Container>

    <Container>
      <TextContainer>
        <p>
          In his latest book, Sadhguru debunks the concept of karma as a mere
          balance sheet of virtues and sins, rewards and punishments. Rather,
          karma is a tool designed to put people back into the driver's seat of
          their own lives &mdash; and into the position of navigators, not
          passive passengers. So what is karma? Karma is not a law imposed from
          above; it does not allow you to outsource your responsibility anywhere
          else, or blame others for your fate. Karma means action: your action,
          your responsibility. It is not some external system of crime and
          punishment, but an internal cycle generated by you. Accumulation of
          karma is determined only by your intention and the way you respond to
          anything and everything that happens to you.&nbsp;
        </p>
        <p>
          According to Sadhguru, karma empowers you to "ride joyfully toward the
          destination of your choice, secure in the knowledge that you are in
          control of your own journey. It is my wish and my blessing that every
          human being should be able to craft their own destiny."
        </p>
        <p>
          The book seeks to highlight Karma not as the predestined, fatalistic
          idea it is mistaken for, but as the empowering, dynamic tool that it
          is, that one can utilize to take charge of their life. What karma
          means is that every individual must see their responsibility in
          shaping their own life and the world around them.
        </p>
      </TextContainer>
    </Container>
  </Box>
)

const AboutSadhguruSection = () => (
  <Box bgColor={'#6e6d7c'}>
    <Box position={'relative'} pt={'10%'} pb={{ base: 0, md: '10%' }}>
      <Container maxW={{ base: '500px', md: 'container.md' }}>
        <TextContainer color={'white'} width={{ base: '100%', md: '60%' }}>
          <p>
            Over the past four decades, Sadhguru has drawn millions of people to
            his yoga and meditation programs. A dynamic and highly sought-after
            speaker, he has addressed prestigious international forums such as
            the United Nations, the World Economic Forum, and TED, and is now
            one of YouTube's most-watched people in the wellness and
            spirituality arena.
          </p>

          <p>
            Sadhguru established the Isha Foundation, which is supported by over
            11 million volunteers in 300 cities worldwide. Isha spearheads
            visionary massive-scale humanitarian projects in public health,
            education, and ecology. Sadhguru inspires millions to dedicate their
            time and energy to create an inclusive and conscious world.
          </p>
        </TextContainer>
        <Box
          position={{ base: 'static', md: 'absolute' }}
          bottom={0}
          right={0}
          width={{ base: '100%', md: '60%' }}
          height={'100%'}
          display={'flex'}
          alignItems={'flex-end'}
          justifyContent={'flex-end'}
          transform={['scale(1.2)', 'unset']}
          transformOrigin={'bottom right'}
          mt={[12, 2, 0]}
        >
          <StaticImage
            src={'../images/whatIsKarma/sadhguru.png'}
            alt={'Sadhguru'}
            placeholder={'none'}
            width={1200}
          />
        </Box>
      </Container>
    </Box>
  </Box>
)

export default function WhatIsKarmaPage() {
  return (
    <Layout
      meta={{
        title: 'What is Karma?',
        description:
          'Have you ever wondered or thought about the word karma? What does karma actually mean? Is karma real or a made up concept spread down through the ages? Is karma individual, collective or both? What are the types of karma? Is it simply what goes around comes around or does it mean something more profound than that?',
      }}
    >
      <HeaderSection />
      <TopText />
      <BookSection />
      <EndorsementsSection />
      <AboutSadhguruSection />
      <EventsSection />
    </Layout>
  )
}
